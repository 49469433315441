import React, { useMemo } from "react";
import styled from "styled-components";

import { usePlaybackContext } from "../../contexts/playback";
import {
  LyricsLine as LyricsLineType,
  useTranslationContext,
} from "../../contexts/translation";
import PlayButton from "../common/PlayButton";
import LyricsLine from "../Lyrics/LyricsLine";
import InfoPanel from "../Translation/InfoPanel";
import TrackProgress from "./TrackProgress";
import GeneralButton from "../common/GeneralButton";
import {
  ManualPanel,
  Input,
  OptionWrapper,
  OptionButton,
  MainContent,
} from "../Components";

const Wrapper = styled.div`
  text-align: center;
  font-size: 24px;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
`;
const Artist = styled.div`
  color: #fff;
  font-size: 18px;
  margin-bottom: 8px;
  overflow: hidden;
  flex: 1;
`;
const OverflowText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SongTitle = styled(OverflowText)`
  font-size: 24px;
  margin-bottom: 4px;
  font-weight: 600;
`;
const TopControls = styled.div`
  display: flex;
  margin-bottom: 4px;
`;
const Controls = styled.div`
  padding: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;
const Tips = styled.div`
  position: relative;
`;
const ToggleButton = styled.button<{ isActive: boolean }>`
  padding: 8px;
  font-size: 12px;
  border-radius: 24px;
  border: 2px solid transparent;
  background: ${({ isActive }) => (isActive ? "#fff" : "transparent")};
  color: ${({ isActive }) => (isActive ? "green" : "grey")};
  border-color: ${({ isActive }) => (isActive ? "green" : "transparent")};
`;
const FloatPanel = styled.div<{ align: string }>`
  position: absolute;
  z-index: 4;
  top: -40px;
  ${({ align }) => align};
`;

const AlbumThumbnail = styled.img`
  border-radius: 8px;
  margin-right: 8px;
`;

type TrackContentProps = {
  item?: any;
  children: React.ReactNode;
};

const TrackContent: React.FC<TrackContentProps> = ({ children }) => {
  const { activeTranslation, tokenHighlight, findLyrics, getSynced } =
    useTranslationContext();
  const { seekToPosition, playbackState, fetchLyrics, play, pause } =
    usePlaybackContext();
  const item = React.useMemo(() => playbackState?.item, [playbackState?.item]);
  const [searchValue, setSearchValue] = React.useState<string | undefined>("");
  const [manual, setManual] = React.useState(false);
  const [searchResults, setSearchResult] = React.useState<any[]>();
  const [showManual, setShowManual] = React.useState(false);

  const [autoScroll, setAutoScroll] = React.useState(true);

  const [activeSlot, setActiveSlot] = React.useState(0);
  const [lyrics, setLyrics] = React.useState<
    LyricsLineType[] | undefined | null
  >();
  const itemRefs: { [key: string]: any } = React.useMemo(() => ({}), []);

  const updateLyrics = React.useCallback(async () => {
    if (!item) return;
    setManual(false);
    setSearchResult(undefined);
    setSearchResult(undefined);
    const result = await fetchLyrics({
      title: item.name,
      artist: item.artists.map((a) => a.name).join(","),
    });
    setLyrics(result);
  }, [fetchLyrics, item?.id]);

  const searchLyrics = React.useCallback(async () => {
    if (!searchValue) return;
    await findLyrics({
      title: searchValue,
      artist: item?.artists ? item.artists.map((a) => a.name).join(",") : "",
      searchCallback: (items) => {
        setSearchResult(items);
      },
    });
  }, [searchValue, findLyrics, item]);

  const manaulFindLyrics = React.useCallback(
    async (lyricsId: string) => {
      if (!searchValue) return;
      const result = await fetchLyrics({
        lyricsId,
        title: searchValue,
        artist: item?.artists ? item.artists.map((a) => a.name).join(",") : "",
      });

      setLyrics(result);
      setManual(true);
    },
    [searchValue, fetchLyrics, item]
  );

  const scrollToLine = React.useCallback(
    (el?: HTMLDivElement) => {
      if (!el || !autoScroll || tokenHighlight) return;
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    [autoScroll, tokenHighlight]
  );

  const updateActiveSlot = React.useCallback(
    (newSlot: number) => {
      setActiveSlot(newSlot);
      const id = lyrics?.[newSlot]?.id;
      if (id !== undefined && itemRefs[id]) {
        scrollToLine(itemRefs[id]);
      }
    },
    [itemRefs, lyrics, scrollToLine]
  );
  const jumpToLine = React.useCallback(
    (line: LyricsLineType) => {
      const slotId = lyrics?.findIndex((p) => p.id === line.id);
      if (slotId !== undefined) {
        updateActiveSlot(slotId);
      }
      seekToPosition(line);
    },
    [seekToPosition, updateActiveSlot, lyrics]
  );

  React.useEffect(() => {
    updateLyrics();
  }, [updateLyrics]);

  React.useEffect(() => {
    if (activeTranslation) {
      scrollToLine(itemRefs[activeTranslation.line.id]);
    }
  }, [activeTranslation, itemRefs, scrollToLine]);

  React.useEffect(() => {
    if (item?.id) {
      updateActiveSlot(0);
    }
  }, [item?.id]);
  const thumbnail = item?.album?.images?.[2];

  const manualOptions = useMemo(
    () =>
      showManual ? (
        <ManualPanel>
          <div>
            <Input
              type="text"
              aria-label="search"
              placeholder="Find Lyrics"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <GeneralButton onClick={() => searchLyrics()}>
              Find Lyrics
            </GeneralButton>
          </div>
          <OptionWrapper>
            {searchResults?.map((s, idx) => (
              <OptionButton
                key={idx}
                onClick={async () => {
                  setShowManual(false);
                  // manaulFindLyrics(s.id);
                  const result = await getSynced({ lyrics: s.lyrics });
                  setLyrics(result);
                }}
              >
                {s.title}
              </OptionButton>
            ))}
          </OptionWrapper>
          {manual && !!lyrics && (
            <div>
              <GeneralButton onClick={() => setLyrics(null)}>
                Find again
              </GeneralButton>
            </div>
          )}
        </ManualPanel>
      ) : null,
    [manaulFindLyrics, searchLyrics, searchResults, searchValue]
  );

  return (
    <Wrapper>
      {children}
      <div>
        <Controls>
          <TopControls>
            {thumbnail && (
              <AlbumThumbnail
                src={thumbnail.url}
                height={thumbnail.height}
                width={thumbnail.width}
                alt="album"
              />
            )}
            {item && (
              <Artist>
                <SongTitle>{item.name}</SongTitle>
                <OverflowText>
                  {item.artists.map((a) => a.name).join(", ")}
                </OverflowText>
              </Artist>
            )}

            <div>
              <PlayButton
                onClick={
                  item
                    ? () =>
                        playbackState?.is_playing ? pause() : play(item.uri)
                    : undefined
                }
                isPlaying={!!playbackState?.is_playing}
              />
            </div>
          </TopControls>
          <TrackProgress
            lyrics={lyrics}
            activeSlot={activeSlot}
            updateActiveSlot={updateActiveSlot}
          />
          {manualOptions}
        </Controls>
      </div>
      <MainContent>
        {!lyrics && playbackState && <div>No Lyrics found :(</div>}

        {lyrics?.map((line, idx) => (
          <div
            key={idx}
            ref={(el) => {
              itemRefs[line.id] = el;
            }}
          >
            <LyricsLine
              line={line}
              isPlaying={idx === activeSlot}
              jumpToLine={() => jumpToLine(line)}
            />
          </div>
        ))}
      </MainContent>

      <Tips>
        <FloatPanel align="left: 8px;">
          <ToggleButton
            onClick={() => setShowManual((prev) => !prev)}
            isActive={showManual}
          >
            Manual
          </ToggleButton>
        </FloatPanel>

        <FloatPanel align="right: 8px;">
          <ToggleButton
            isActive={autoScroll}
            onClick={() => setAutoScroll((prev) => !prev)}
          >
            AutoScroll
          </ToggleButton>
        </FloatPanel>

        {activeTranslation && (
          <InfoPanel
            // translation={activeTranslation.result}
            // token={activeTranslation.token}
            jumpToLine={jumpToLine}
          />
        )}
      </Tips>
    </Wrapper>
  );
};
export default React.memo(TrackContent);
