import { usePlaybackContext } from "../../contexts/playback";
import { AlbumHeader } from "../Components";

const AlbumInfoHeader = () => {
  const { album } = usePlaybackContext();

  return (
    <AlbumHeader>
      <div style={{ overflow: "hidden" }}>
        <h2>{album?.name}</h2>
        <h4>{album?.artists?.map((art) => art.name).join(", ")}</h4>
        <h6>{album?.release_date}</h6>
      </div>
    </AlbumHeader>
  );
};

export default AlbumInfoHeader;
