import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useTranslationContext,
  LyricsLine as LyricsLineType,
} from "../contexts/translation";
import GeneralButton from "./common/GeneralButton";
import {
  ManualPanel,
  Input,
  OptionWrapper,
  OptionButton,
  MainContent,
  AlbumArt,
  AlbumHeader,
} from "./Components";
import styled from "styled-components";
import LyricsLine from "./Lyrics/LyricsLine";
import InfoPanel from "./Translation/InfoPanel";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  position: relative;
`;
const ContentWrapper = styled.div`
  text-align: center;
  font-size: 24px;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
`;
const SearchBar = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
const SongHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 36px;
`;
export default function ManualMode() {
  const [hideSearch, setHideSearch] = useState(false);
  const { findLyrics, getSynced, activeTranslation, language } =
    useTranslationContext();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [searchArtist, setSearchArtist] = useState<string | undefined>("");
  const [searchAlbum, setSearchAlbum] = useState<string | undefined>("");
  const [searchResults, setSearchResults] = useState<any[]>();
  const [lyrics, setLyrics] = useState<LyricsLineType[] | undefined | null>();
  const [selectedSong, setSelectedSong] = useState<{
    lyrics: string;
    album: string;
    artist: string;
    title: string;
  }>();
  const itemRefs: { [key: string]: any } = useMemo(() => ({}), []);

  const searchLyrics = useCallback(async () => {
    await findLyrics({
      title: searchValue || "",
      artist: searchArtist || "",
      album: searchAlbum || "",
      searchCallback: (items) => {
        setSearchResults(items);
      },
    });
  }, [searchValue, searchArtist, searchAlbum, findLyrics]);

  const updateLyrics = useCallback(async () => {
    if (!selectedSong) return;
    const result = await getSynced({
      lyrics: `[00:00.000] ${selectedSong.title}\n${selectedSong.lyrics}`,
    });
    setLyrics(result);
  }, [selectedSong, getSynced]);

  useEffect(() => {
    if (language && selectedSong) {
      updateLyrics();
    }
  }, [language, selectedSong, updateLyrics]);

  return (
    <>
      <AlbumArt>
        {selectedSong && (
          <div style={{ position: "absolute", bottom: 8, left: 0 }}>
            <AlbumHeader>
              <h2>{selectedSong.title}</h2>
              <h4>{selectedSong.artist}</h4>
              <h6>{selectedSong.album}</h6>
            </AlbumHeader>
          </div>
        )}
      </AlbumArt>
      <Wrapper>
        {!hideSearch ? (
          <>
            <ManualPanel>
              <div>
                <Input
                  type="text"
                  aria-label="search"
                  placeholder="Find Lyrics"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />{" "}
                <Input
                  type="text"
                  aria-label="search"
                  placeholder="Artist"
                  value={searchArtist}
                  onChange={(e) => setSearchArtist(e.target.value)}
                />
                <Input
                  type="text"
                  aria-label="search"
                  placeholder="Album"
                  value={searchAlbum}
                  onChange={(e) => setSearchAlbum(e.target.value)}
                />
                <GeneralButton onClick={() => searchLyrics()}>
                  Find Lyrics
                </GeneralButton>
                <GeneralButton onClick={() => setHideSearch(true)}>
                  Hide
                </GeneralButton>
              </div>
            </ManualPanel>

            <OptionWrapper>
              {searchResults?.map((s, idx) => (
                <OptionButton
                  key={idx}
                  onClick={async () => {
                    setSelectedSong(s);
                  }}
                >
                  {s.title}
                </OptionButton>
              ))}
            </OptionWrapper>
          </>
        ) : (
          <SearchBar>
            <GeneralButton onClick={() => setHideSearch(false)}>
              Search Lyrics
            </GeneralButton>
          </SearchBar>
        )}
        <ContentWrapper>
          <MainContent>
            {lyrics?.map((line, idx) => (
              <div
                key={idx}
                ref={(el) => {
                  itemRefs[line.id] = el;
                }}
              >
                <LyricsLine
                  line={line}
                  isPlaying={false}
                  jumpToLine={() => {}}
                  //   jumpToLine={() => jumpToLine(line)}
                />
              </div>
            ))}
          </MainContent>
        </ContentWrapper>

        {activeTranslation && (
          <InfoPanel
            // translation={activeTranslation.result}
            // token={activeTranslation.token}
            jumpToLine={() => {}}
          />
        )}
      </Wrapper>
    </>
  );
}
