import styled from "styled-components";
import { palette } from "../theme";

export const Input = styled.input`
  padding-right: 30px;
  font-family: inherit;
  border: none;
  background: #fff;
  outline: none;
  transition: 0.1s ease-in;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 8px;
  border-radius: 30px;
  &:focus {
    box-shadow: inset 0 0 0 2px #1db954;
  }
`;
export const ManualPanel = styled.div`
  text-align: left;
  max-height: 200px;
  background: #000;
  padding: 16px;
  margin-top: 16px;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const OptionButton = styled.button`
  border: 0;
  background: #222;
  padding: 12px 18px;

  flex: 1;
  font-size: 20px;
  text-align: left;
  flex: 1;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    transform: scale(1.05);
    border-radius: 8px;
    color: #1db954;
  }
`;
export const MainContent = styled.main`
  color: #fff;
  animation: 0.5s linear fadein;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AlbumArt = styled.div<{ image?: string }>`
  text-align: center;
  font-size: 24px;
  flex: 1;
  min-height: 400px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  ${({ image }) =>
    image
      ? `
    background-image: ${`url(${image})`};
    `
      : `background: linear-gradient(
        ${palette.spotifyGreen},
        ${palette.pink},
        ${palette.purple}
      );`}
  @media (max-width: 768px) {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
  }
`;

export const AlbumHeader = styled.div`
  text-align: left;
  padding: 16px 16px 16px 24px;
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    font-size: 36px;
  }
  h4 {
    font-size: 24px;
  }
  h6 {
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
  }
`;
